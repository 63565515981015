import React from "react";
import "./Assrm.css";
import { NavLink } from "react-router-dom";

import logo from "./AssrmFiles/logo-ASSRM_page-0001-150x150.jpg";

//program
import BscAssrm from "./AssrmFiles/BscAssrm.pdf";
import MscAssrm from "./AssrmFiles/MscAssrm.pdf";
//Key Members

import AKUimg from "./AssrmFiles/AKUimg.jpg";

import GagandeepKimg from "./Assrm faculty/Dr. Gagandeep Kaur/gagandeep-mam.jpg";

// facalty
import amandeepImg from "./Assrm faculty/Ms. Amandeep Kaur/IMG_0045-scaled-e1686560994297-150x150.jpg";
import soniaImg from "./Assrm faculty/Ms. Sonia/IMG_0051-scaled-e1686561162316.jpg";
import baljinderImg from "./Assrm faculty/Mr. Baljinder Singh/IMG_0062-scaled-e1686561287881-150x150.jpg";
import sakshiSimg from "./Assrm faculty/Ms. Sakshi Sudha/IMG_0054-scaled-e1686561325717-150x150.jpg";
import nihalImg from "./Assrm faculty/Mr. Nihal Singh/IMG_0037-scaled-e1686561378714-150x150.jpg";

import NikhilaSimg from "./Assrm faculty/Ms. Nikhila/IMG_0067-scaled-e1686561424530-150x150.jpg";

//new activities
import oldActivity01 from "./AssrmFiles/Industrial-and-Exibition-Visit-Pragati-Maidan-2019.pdf";
import oldActivity02 from "./AssrmFiles/NGO-Visist-Report.pdf";
import oldActivity03 from "./AssrmFiles/Stadium-Visit-Report.pdf";
import oldActivity04 from "./AssrmFiles/sports-inida-conference-report-.pdf";
import oldActivity05 from "./AssrmFiles/Bronze-medalist-Ayush-Mawri-Khelo-India-1.pdf";
import oldActivity06 from "./AssrmFiles/Report-on-Players-Achivement-Raghu-and-Pranav-Dalal.pdf";
import oldActivity07 from "./AssrmFiles/webinar-BUILDING-OUR-INNER-STRENGTH.pdf";
import oldActivity08 from "./AssrmFiles/webinar-Does-Covid-19-Affect-the-Growth-and-Development-of-Children.pdf";
import oldActivity09 from "./AssrmFiles/Webinar-HERD-IMMUNITY-AND-DOES-AN-ATHLETE-HAVE-A-BETTER-IMMUNE-SYSTEM-DUE-TO-FITNESS-CAPABILITIES.pdf";
import oldActivity10 from "./AssrmFiles/Webinar-Managing-an-Elite-Players-Performance-and-mental-Health-during-COVID-19.pdf";
import oldActivity11 from "./AssrmFiles/Webinar-Managing-Anxiety-during-Covid-19.pdf";
import oldActivity12 from "./AssrmFiles/webinarchange-how-to-adapt-cope-and-respond.pdf";
import oldActivity13 from "./AssrmFiles/REPORT-ON-GURPURAV-CELEBRATION-1.pdf";
import oldActivity14 from "./AssrmFiles/Report-on-Yoga-Day.pdf";
import oldActivity16 from "./AssrmFiles/Student-activities-2022-23ASSRM.pdf";

// gallwry
import gal1 from "./AssrmFiles/gallery/gallary01.png";
import gal2 from "./AssrmFiles/gallery/gallary02.jpg";
import gal3 from "./AssrmFiles/gallery/gallary03.png";
import gal4 from "./AssrmFiles/gallery/gallary04.png";
import gal5 from "./AssrmFiles/gallery/gallery05.jpeg";
import gal6 from "./AssrmFiles/gallery/gallary06.jpg";
import gal7 from "./AssrmFiles/gallery/gallery07.jpeg";
import gal8 from "./AssrmFiles/gallery/gallery08.jpeg";
import gal9 from "./AssrmFiles/gallery/gallery09.jpeg";
import gal10 from "./AssrmFiles/gallery/gallery10.jpeg";
import gal11 from "./AssrmFiles/gallery/gallery11.jpeg";
import gal12 from "./AssrmFiles/gallery/gallery12.jpeg";
import gal13 from "./AssrmFiles/gallery/gallery13.jpeg";
import gal14 from "./AssrmFiles/gallery/gallary14.jpg";
import gal15 from "./AssrmFiles/gallery/gallery15.jpeg";
import gal16 from "./AssrmFiles/gallery/gallery16.jpeg";
import gal17 from "./AssrmFiles/gallery/gallery17.jpeg";
import gal18 from "./AssrmFiles/gallery/gallary18.jpg";
import gal19 from "./AssrmFiles/gallery/gallary19.jpg";
import gal20 from "./AssrmFiles/gallery/gallary20.jpg";

// Student Achievements

import sal1 from "./AssrmFiles/gallery/Student Archivement/sa01.jpg";
import sal2 from "./AssrmFiles/gallery/Student Archivement/sa02.jpg";
import sal3 from "./AssrmFiles/gallery/Student Archivement/sa03.jpg";
import sal4 from "./AssrmFiles/gallery/Student Archivement/sa04.jpg";
import sal5 from "./AssrmFiles/gallery/Student Archivement/sa05.jpg";
import sal6 from "./AssrmFiles/gallery/Student Archivement/sa06.jpeg";
import sal7 from "./AssrmFiles/gallery/Student Archivement/sa07.jpeg";
import sal8 from "./AssrmFiles/gallery/Student Archivement/sa08.jpeg";
import sal9 from "./AssrmFiles/gallery/Student Archivement/sa09.png";
import sal10 from "./AssrmFiles/gallery/Student Archivement/sa10.png";
import sal11 from "./AssrmFiles/gallery/Student Archivement/sa11.png";
import sal12 from "./AssrmFiles/gallery/Student Archivement/sa12.png";
import sal13 from "./AssrmFiles/gallery/Student Archivement/sa13.jpg";
import sal14 from "./AssrmFiles/gallery/Student Archivement/sa14.png";
import sal15 from "./AssrmFiles/gallery/Student Archivement/sa15.png";
import ReportOnSeminamrANdWorkShoponStrengthandcondinsports from '../../School/SchoolofPhysiotherapy/SopFiles/Report on Seminar and Workshop on Strength and Conditioning in Sports.pdf'

// import InternationalSeminarOfMedicine from './AssrmFiles/Report on International Seminar on Integrated Medicine'
import InternationalSeminarOfMedicine from './AssrmFiles/Report on International Seminar on Integrated Medicine.pdf'
import InternationalWorkshoponMindTrainingofAthletes from './AssrmFiles/International workshop on mind training of athletes.pdf'
import PatialaSeminarReport from './AssrmFiles/Patiala Seminar report.pdf'

function Assrm() {
  return (
    <div className="dpsru-every-component-css">
      <div className="logo-cmys-container">
        <img src={logo} alt="logo" style={{ width: "70%", margin: "auto" }} />
      </div>
      <h2 className="dpsru-primary-heading-for-every-page">
        Academy of Sports Sciences Research and Management(ASSRM)
      </h2>
      <p className="assrm-main-paragrauph">
        At Academy of Sports Sciences Research and Management, we share the same
        passion in wanting to make a difference to young individuals, helping
        them to get involved in sports and, most essentially, enjoy the sport.
        All of the ASSRM staff is talented and experienced specialists who help
        to inspire young individuals to lead a positive, rewarding, and healthy
        lives.
      </p>

      <div className="our-vision-mission-box-assrm">
        <div className="our-vision-mission-inner-card-assrm">
          <h3 className="our-vision-mission-heading-assrm">Our Vision</h3>
          <p>
            {" "}
            To become the leading destination for research, training, and
            education in Sports Sciences and Management. We strive to support
            the application of sports science, catering to the health and
            fitness requirements of a diverse population.
          </p>
        </div>
        <div className="our-vision-mission-inner-card-assrm">
          <h3 className="our-vision-mission-heading-assrm">Our Mission</h3>
          <p>
            {" "}
            To gear students with the necessary operational management tools and
            research proficiencies to ascertain their employability in
            industries, institutes, and communities associated to sports.
          </p>
        </div>
      </div>

      <div className="program-container-assrm">
        <h3 className="dpsru-secondary-heading-for-every-page">Programs</h3>
        <ul>
          <li>
            B.Sc. (Hons.) Sport Science : <a href={BscAssrm}>Syllabus</a>
          </li>
          <li>
            M.Sc. Sport Science : <a href={MscAssrm}>Syllabus</a>
          </li>
        </ul>

        <h2 className="dpsru-secondary-heading-for-every-page">
          Our Infrastructure
        </h2>
        <div className="inner-container">
          <ul>
            <li>
              The infrastructure of the school is always connected with quality
              and learning. Thus, a sound and good infrastructure is the basic
              and essential requirement. Infrastructure is always connected to a
              specific goal.
            </li>
            <li>
              We at the Academy of Sports Sciences Research and Management have
              managed the infrastructure in such a manner so that it caters to
              our goals.
            </li>
            <li>
              As part of our commitment to pursuing excellence and presenting
              our students a school experience, which allows them to maximise
              their learning experience, we have been founded to offer the
              facilities they need to explore their skills.
            </li>
            <li>
              Sports are a vital area of interest for our students, and our
              developing facilities offer them the facilities they need to
              establish their competencies.
            </li>
          </ul>
        </div>
      </div>

      {/* <h2 className="dpsru-secondary-heading-for-every-page">Key Members</h2> */}
      {/* <section className="key-members-section-assrm">

                <div className="key-members-container-assrm-each-img-container">
                    <a href={AnshulBcv}> <img src={AnshulBimg} alt="key members img" /> </a>
                    <h2 className="key-members-name-assrm">Mr. Anshul Bagai</h2>
                    <h3 className="key-members-details-assrm">Director,
                        ASSRM</h3>
                </div>

                <div className="key-members-container-assrm-each-img-container">
                    <a href={AKUcv}> <img src={AKUimg} alt="key members img" /> </a>
                    <h2 className="key-members-name-assrm">Dr. Arun Kumar Uppal</h2>
                    <h3 className="key-members-details-assrm">Senior Professor & Academic Advisor</h3>
                </div>


                <div className="key-members-container-assrm-each-img-container">
                    <a href={RaviScv}> <img src={RaviSimg} alt="key members img" /> </a>
                    <h2 className="key-members-name-assrm">Mr. Ravi Shankar</h2>
                    <h3 className="key-members-details-assrm">Chief Administrative Officer,
                        ASSRM</h3>
                </div>

                <div className="key-members-container-assrm-each-img-container">
                    <a href={GagandeepKcv}> <img src={GagandeepKimg} alt="key members img" /> </a>
                    <h2 className="key-members-name-assrm">Dr. Gagandeep Kaur</h2>
                    <h3 className="key-members-details-assrm">Assistant Professor </h3>
                </div>
            </section> */}

      <section className="faculty-assrm">
        <h3 className="dpsru-secondary-heading-for-every-page">Our Faculty</h3>
        {/* each faculty Box */}
        <NavLink
          to="/dr-ak-uppal"
          className="each-faculty-box-navlink-container"
        >
          <div className="each-faculty-box">
            <div className="each-faculty-img">
              <img src={AKUimg} alt="faculty-profile" />
            </div>
            <div className="each-faculty-name-and-post">
              <h3 className="faculty-name">Dr. Arun Kumar Uppal</h3>
              <h4 className="faculty-position">
                Senior Professor & Academic Advisor
              </h4>
            </div>
          </div>{" "}
        </NavLink>
        <hr />
        {/* each faculty Box */}
        <NavLink
          to="/dr-gagandeep-kaur"
          className="each-faculty-box-navlink-container"
        >
          <div className="each-faculty-box">
            <div className="each-faculty-img">
              <img src={GagandeepKimg} alt="faculty-profile" />
            </div>
            <div className="each-faculty-name-and-post">
              <h3 className="faculty-name">Dr. Gagandeep Kaur</h3>
              <h4 className="faculty-position">
                Assistant Professor & Nodal Officer
              </h4>
            </div>
          </div>{" "}
        </NavLink>
        <hr />
        {/* each faculty Box */}
        {/* <NavLink to="/ms-amandeep-kaur" className="each-faculty-box-navlink-container">
        <div className="each-faculty-box">
          <div className="each-faculty-img">
          
              <img src={amandeepImg} alt="faculty-profile" />
          
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Ms. Amandeep Kaur</h3>
            <h4 className="faculty-position">Assistant Professor </h4>
          </div>
        </div> </NavLink>{" "}
        <hr /> */}
        {/* each faculty Box */}
        <NavLink to="/ms-sonia" className="each-faculty-box-navlink-container">
          <div className="each-faculty-box">
            <div className="each-faculty-img">
              <img src={soniaImg} alt="faculty-profile" />
            </div>
            <div className="each-faculty-name-and-post">
              <h3 className="faculty-name">Ms. Sonia</h3>
              <h4 className="faculty-position">Assistant Professor</h4>
            </div>
          </div>
        </NavLink>
        <hr />
        {/* each faculty Box */}
        <NavLink
          to="/mr-baljinder-singh"
          className="each-faculty-box-navlink-container"
        >
          <div className="each-faculty-box">
            <div className="each-faculty-img">
              <img src={baljinderImg} alt="faculty-profile" />
            </div>
            <div className="each-faculty-name-and-post">
              <h3 className="faculty-name">Mr. Baljinder</h3>
              <h4 className="faculty-position">Assistant Professor</h4>
            </div>
          </div>
        </NavLink>
        <hr />
        {/* each faculty Box */}
        {/* <NavLink to="/ms-sakshi-sudha" className="each-faculty-box-navlink-container">
        <div className="each-faculty-box">
          <div className="each-faculty-img">
          
              <img src={sakshiSimg} alt="faculty-profile" />
           
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Ms. Sakshi Sudha</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div></NavLink>
        <hr /> */}
        {/* each faculty Box */}
        <NavLink
          to="/mr-nihal-singh"
          className="each-faculty-box-navlink-container"
        >
          <div className="each-faculty-box">
            <div className="each-faculty-img">
              <img src={nihalImg} alt="faculty-profile" />
            </div>
            <div className="each-faculty-name-and-post">
              <h3 className="faculty-name">Mr. Nihal Singh</h3>
              <h4 className="faculty-position">Assistant Professor</h4>
            </div>
          </div>
        </NavLink>
        <hr />
        <h3 className="dpsru-secondary-heading-for-every-page">
          Our Visiting Faculty
        </h3>
        {/* each faculty Box */}
        <NavLink
          to="/ms-Nikhila"
          className="each-faculty-box-navlink-container"
        >
          <div className="each-faculty-box">
            <div className="each-faculty-img">
              <img src={NikhilaSimg} alt="faculty-profile" />
            </div>
            <div className="each-faculty-name-and-post">
              <h3 className="faculty-name">Ms. Nikhila</h3>
              <h4 className="faculty-position">Pharmacy</h4>
            </div>
          </div>
        </NavLink>
        <hr />
      </section>
      <section className="our-infrastacture-assrm">
        <h1 className="dpsru-secondary-heading-for-every-page">
          Our Infrastructure
        </h1>
        <ul>
          <li>
            The infrastructure of the school is always connected with quality
            and learning. Thus, a sound and good infrastructure is the basic and
            essential requirement. Infrastructure is always connected to a
            specific goal.
          </li>
          <li>
            We at the Academy of Sports Sciences Research and Management have
            managed the infrastructure in such a manner so that it caters to our
            goals.
          </li>
          <li>
            As part of our commitment to pursuing excellence and presenting our
            students a school experience, which allows them to maximise their
            learning experience, we have been founded to offer the facilities
            they need to explore their skills.
          </li>
          <li>
            Sports are a vital area of interest for our students, and our
            developing facilities offer them the facilities they need to
            establish their competencies.
          </li>
        </ul>
      </section>

      {/* activity */}
      <div className="dipsar-activity-container results-data-link-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Activities</h2>

        <ul>
          <li>
            <a href={PatialaSeminarReport}>
            2 Days National seminar at punjabi University Patiala
            </a>
          </li>
          <li>
            <a href={InternationalWorkshoponMindTrainingofAthletes}>
            International workshop on mind training of athletes
            </a>
          </li>
          <li>
            <a href={InternationalSeminarOfMedicine}>
            Report on International Seminar on Integrated Medicine
            </a>
          </li>
          <li>
            <a href={ReportOnSeminamrANdWorkShoponStrengthandcondinsports}>
            Report on Seminar and Workshop on Strength and Conditioning in Sports
            </a>
          </li>
          <li>
            <a href={oldActivity01}>
              Industrial and Exibition Visit Pragati Maidan 2019
            </a>
          </li>
          <li>
            <a href={oldActivity02}>NGO Visist Report</a>
          </li>
          <li>
            <a href={oldActivity03}>Stadium Visit Report</a>
          </li>
          <li>
            <a href={oldActivity04}>Sports India Conference Report</a>
          </li>
          <li>
            <a href={oldActivity05}>Bronze medalist Ayush Mawri Khelo India</a>
          </li>
          <li>
            <a href={oldActivity06}>
              Report on Players Achivement Raghu and Pranav Dalal
            </a>
          </li>
          <li>
            <a href={oldActivity07}>Webinar Building Our Inner Strength</a>
          </li>
          <li>
            <a href={oldActivity08}>
              Webinar Does Covid-19 Affect the Growth and Development of
              Children
            </a>
          </li>
          <li>
            <a href={oldActivity09}>
              Webinar Herd Immunity And Does An Athlete Have A Better Immune
              System Due To Fitness Capabilities
            </a>
          </li>
          <li>
            <a href={oldActivity10}>
              Webinar Managing an Elite Player’s Performance and mental Health
              during COVID-19
            </a>
          </li>
          <li>
            <a href={oldActivity11}>Webinar Managing Anxiety during Covid-19</a>
          </li>

          <li>
            <a href={oldActivity12}>
              Webinar change How To Adapt Cope And Respond
            </a>
          </li>

          <li>
            <a href={oldActivity13}>"Gurupurav" Celebration</a>
          </li>

          <li>
            <a href={oldActivity14}>"Yoga Day" Celebration</a>
          </li>

          <li>
            <a>Sports India Expro-2022 Visit</a>
          </li>

          <li>
            <a href={oldActivity16}>
              Student activities 1st Jan- 30th April, 2023
            </a>
          </li>

          <h2 className="dpsru-secondary-heading-for-every-page">Gallery</h2>

          <div className="cmyp-gallery-container">
            <img src={gal1} alt="gallary" />
            <img src={gal2} alt="gallary" />
            <img src={gal3} alt="gallary" />
            <img src={gal4} alt="gallary" />
            <img src={gal5} alt="gallary" />
            <img src={gal6} alt="gallary" />

            <img src={gal7} alt="gallary" />
            <img src={gal8} alt="gallary" />
            <img src={gal9} alt="gallary" />
            <img src={gal10} alt="gallary" />
            <img src={gal11} alt="gallary" />
            <img src={gal12} alt="gallary" />
            <img src={gal13} alt="gallary" />
            <img src={gal14} alt="gallary" />
            <img src={gal15} alt="gallary" />
            <img src={gal16} alt="gallary" />
            <img src={gal17} alt="gallary" />
            <img src={gal18} alt="gallary" />
            <img src={gal19} alt="gallary" />
            <img src={gal20} alt="gallary" />
          </div>

          <h2 className="dpsru-secondary-heading-for-every-page">
            Student Achievements
          </h2>

          <div className="cmyp-gallery-container">
            <img src={sal1} alt="gallary" />
            <img src={sal2} alt="gallary" />
            <img src={sal3} alt="gallary" />
            <img src={sal4} alt="gallary" />
            <img src={sal5} alt="gallary" />
            <img src={sal6} alt="gallary" />s
            <img src={sal7} alt="gallary" />
            <img src={sal8} alt="gallary" />
            <img src={sal9} alt="gallary" />
            <img src={sal10} alt="gallary" />
            <img src={sal11} alt="gallary" />
            <img src={sal12} alt="gallary" />
            <img src={sal13} alt="gallary" />
            <img src={sal14} alt="gallary" />
            <img src={sal15} alt="gallary" />
          </div>
        </ul>
      </div>
    </div>
  );
}

export default Assrm;
